<template>
  <div class="videos">
    <div class="head">
      <div></div>
      <!--      <el-input-->
      <!--          placeholder="请输入内容"-->
      <!--          prefix-icon="el-icon-search"-->
      <!--          v-model="search">-->
      <!--      </el-input>-->
    </div>
    <div class="videoItems">
      <div class="box" v-for="(item , index) in videos" :key="index">
        <div class="item" @mouseover="showPlay(item)" @mouseleave="unShowPlay(item)" @click="showVideo(item)">
          <div class="playBtn" v-show="item.show">
            <div class="triangle-right"></div>
          </div>
        </div>
        <div class="lable">video0{{index+1}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {v4 as uuid4} from "uuid"
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
    return {
      search: '',
      show: false,
      videos: [{id:'video01',show:false},{id:'video02',show:false},{id:'video03',show:false},{id:'video04',show:false},{id:'video05',show:false}]
      }
  },
  methods: {
    showPlay(item) {
      item.show = true;
    },
    unShowPlay(item) {
      item.show = false;
    },
    showVideo(item) {
      window.open('http://leftmain2.invisionhealth.cn/items/?id='+item.id,'_blank')
      // this.$router.push({path: '/items/', query: {id: item.id}})
      // this.video = true
    }
  },
  mounted() {
    let userId = uuid4();
    sessionStorage.setItem("user", userId);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
.videos{
  padding-bottom: 50px;
}
.videoItems {
  display: flex;
  flex-wrap: wrap;
  /*justify-content: center;*/
  padding: 0 10px 0 20px;
}

@media (max-width: 710px) and (min-width: 490px){
  .videoItems {
    padding-left: calc((100% - 460px)/2);
  }
}
@media (max-width: 940px) and (min-width: 720px){
  .videoItems {
    padding-left: calc((100% - 690px)/2);
  }
}
@media (max-width: 1160px) and (min-width: 950px){
  .videoItems {
    padding-left: calc((100% - 920px)/2);
  }
}
@media (max-width: 1410px) and (min-width: 1200px){
  .videoItems {
    padding-left: calc((100% - 1150px)/2);
  }
}
@media (max-width: 1640px) and (min-width: 1440px){
  .videoItems {
    padding-left: calc((100% - 1380px)/2);
  }
}
@media (max-width: 1870px) and (min-width: 1680px){
  .videoItems {
    padding-left: calc((100% - 1610px)/2);
  }
}

@media (max-width: 500px) {
  .videoItems {
    padding: 0 !important;
  }

  .videoItems .box {
    display: flex !important;
    margin-top: 20px !important;
    margin-left: 0 !important;
    height: 200px !important;
    width: 100% !important;
    border-radius: 0 !important;
    background: darkgray;
    overflow: hidden;
  }

  .videoItems .item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 100% !important;
    border-radius: 10px;
    background-image: url('fei.jpg');
  }

  .videoItems .box .lable {
    text-align: left;
    padding-left: 10px;
    margin-top: 5px;
    line-height: 20px;
    width: calc(100% - 110px) !important;
    height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.videoItems .box {
  display: inline-block;
  margin-top: 50px;
  margin-left: 30px;
  height: 250px;
  width: 200px;
  border-radius: 10px;
  background: darkgray;
  overflow: hidden;
}

.videoItems .box .lable {
  text-align: left;
  padding-left: 10px;
  margin-top: 5px;
  line-height: 20px;
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.videoItems .item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 200px;
  border-radius: 10px;
  background-image: url('fei.jpg');
}

.videoItems .item:hover {
  -webkit-filter: contrast(70%); /* Chrome, Safari, Opera */
  filter: contrast(70%);
}

   .playBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: black;
}

.triangle-right {
  margin-left: 7px;
  opacity: 1;
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-left: 25px solid white;
  border-bottom: 15px solid transparent;
}
</style>
