import Vue from 'vue'
import App from './App.vue'
import VideoPlayer from 'vue-video-player'
import ElementUI from 'element-ui';
// import Router from 'vue-router'

Vue.use(ElementUI)
// Vue.use(Router)
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
Vue.use(VideoPlayer)

Vue.config.productionTip = false

// const routes=[
//   {path:'/main',component:() => import('./components/invVideos')},
//   {path:'/items',component:() => import('./components/invVideos')},
//   //可以配置重定向
//   {path:'/',component:() => import('./components/invVideos')}
//   //或者重新写个路径为空的路由
//   // {path:"",component:page1}
// ]

// const router=new Router({
//   mode:'history',
//   routes
// });

new Vue({
  // router,  // router,
  render: h => h(App),
}).$mount('#app')

