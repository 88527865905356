<template>
  <div id="app">
    <div class="head">
      <img src="./assets/Invision.jpeg" alt="" class="logo" >
<!--      <div>z主页</div>-->
    </div>
<!--    <router-view style="background-color: #F2F2F2"></router-view>-->
    <invVideos style="background-color: #F2F2F2"/>
  </div>
</template>

<script>
import invVideos from './components/invVideos.vue'

export default {
  name: 'App',
  components: {
    invVideos
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.head{
  text-align: left!important;
}
.head .logo{
  width: 80px;
  margin-left: 30px;
  margin-top: 6px;
  margin-bottom: 4px;
}
</style>
